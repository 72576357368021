import React from 'react';
import styled from 'styled-components';

export const BadgeContainerStyles = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${props => sizeObj[props.size]}px;
`;

const sizeObj = {
  sm: 8,
  md: 16,
  lg: 24,
};

const BadgeContainer = ({ children, size = 'sm', style }) => (
  <BadgeContainerStyles style={style} size={size}>
    {children}
  </BadgeContainerStyles>
);

export default BadgeContainer;
