import styled from 'styled-components';
import { BadgeContainerStyles } from '../../molecules/Badge/Container/BadgeContainer';
import {
  Wave,
  WaveWrapper,
} from '../../molecules/Section/SectionBg/SectionBgHexes.styles';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const CaseStudyListWrapper = styled.div`
  overflow: hidden;

  ${WaveWrapper} {
    &::after {
      content: '';
      display: block;
      background-color: ${colors.gray[100]};
      position: absolute;
      width: 100%;
      top: calc(600px + 87.5vw);
      bottom: 0;
    }
  }

  ${Wave} {
    fill: ${colors.gray[100]};
    width: 175vw;
    height: unset;
    position: absolute;
    bottom: initial;
    top: 600px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const CaseStudyListContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;

  .MuiSelect-select svg {
    display: none;
  }

  .case-studies-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 32px 30px;
    margin: 15px 0;
  }

  .load-more-container {
    display: flex;
    justify-content: center;

    .cta {
      background: ${colors.base.white};
      border-color: ${colors.primary[500]};
      color: ${colors.primary[500]};
    }
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`;

export const Heading = styled.h2`
  ${font('display', 'sm', '400')}
  color: ${colors.gray[900]};
  max-width: 970px;
  margin: 15px auto 9px;
  text-align: center;

  ${atMinWidth.sm`
    ${font('display', 'md', '400')}
    margin-bottom: 11px;
  `}

  ${atMinWidth.md`
    ${font('display', 'lg', '400')}
    margin-bottom: 17px;
  `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;

  .badge {
    cursor: pointer;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 500;
    padding: 8px 12px;
  }

  .clear-filters {
    padding-left: 0;
    padding-right: 0;
    font-weight: 400;
  }

  ${BadgeContainerStyles} {
    gap: 16px 24px;
    justify-content: center;

    ${atMinWidth.xl`
      gap: 20px 28px;
    `}
  }
`;
